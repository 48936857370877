<template>
  <div>
    <div class="bg blue">
    </div>
    <div class="container-in checkout"  style=" align-items: center; display: flex; min-height: 100vh; width: 700px;">
      <div class="smart-wrap" >
        <div class="sheet row"  id="smart-wrap" :style=" 'width: 100%; padding:40px; display:block; text-align: center; '">
          <img style="width: 200px; margin: 0 auto; display:block;" :src="publicOrgPay.logoColor[0].url" :alt="publicOrgPay.logoColor[0].url"> <br>
          <h4>Unsubscribe</h4>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
          <div class="input-wrap" style="text-align: left;">
            <label for="">Name</label>
            <input type="text">
          </div>
          <div class="input-wrap" style="text-align: left;">
            <label for="">Email</label>
            <input type="text">
          </div>
          <br>
          <br>
          <ui-button text='Unsubscribe' position='center' color="green" style="width: 250px;"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  let tokenQuote = null
  
  //Tools
  
  import { API, Storage, Auth} from 'aws-amplify'
  import Vuex from "vuex";
  import Swal from 'sweetalert2'
  
  //import html2pdf from 'html2pdf'
  //Components
  import PaperDisplayer from '../components/papers/PaperDisplayer.vue'
  import UiButton from '../components/ui/UiButton.vue';
  //GRAPHQL
  import { smd_processPayment } from '../graphql/mutations'
  import { smd_getRecordObj, smd_getPublicQuoteSet } from '../graphql/queries'

  export default {
    name:'checkout',
    components:{
      PaperDisplayer,
      UiButton,
    },
    data() {
      return {
        data: null,
        total: 0,
        tandc: false,
        date: (new Date()).toISOString().split('T')[0],
        methodPayment: 'undefined',
        emails: [],
        phones: [],
      }
    },
    created() {
      console.clear()
      this.setPublicOrganization(undefined)
      this.setPublicOrganizationData(undefined)
      this.setPublicOrgPay  (undefined)
      this.loading('show')
      this.setUserWeb()
      /*if (this.reload == true){
        this.setStatusReload(false);
        location.reload();
      }*/
      
    },
    methods: {
      ...Vuex.mapActions(['getPublicOrganization','getPublicOrganizationData']),
      ...Vuex.mapMutations(['setStatusReload','setPublicOrganizationData','setPublicOrgPay','setPublicOrganization','setContactSK']),
      //Tools
      loading(action){
        if (action == 'show') {
          document.getElementById("loading").classList.add('show')
          document.getElementById("body").classList.add("noScroll")
        }else{
          document.getElementById("loading").classList.remove('show')
          document.getElementById("body").classList.remove("noScroll")
        }
      },
      setDate(date) {
        //console.clear()
        let value = date
        try {
          let dateToday = new Date().toISOString().split("T")[0];
          if (date.split("-")[0] == dateToday.split("-")[0] ) {
            if (date.split("-")[1] == dateToday.split("-")[1]) {
              switch (parseInt(date.split("-")[2])) {
                case parseInt(dateToday.split("-")[2])+1:
                  value = 'Tomorrow'
                break;
                case parseInt(dateToday.split("-")[2]):
                  value = 'Today'
                break;
                case parseInt(dateToday.split("-")[2])-1:
                  value = 'Yesterday'
                break;
                default:
                  value = new Date(date).toLocaleDateString(navigator.language)
                break;
              }
            }
          }
        } catch (error) {
          console.log(error);
        }
        return value;
      },
      setStyle(total, sheet){
        console.log(this.$router.currentRoute.value.query.mode )
        let value = ''
        if (total != 0 && this.$router.currentRoute.value.query.mode != 'r') {
          console.log('Mostrar');
          if (sheet == 'one') {
            value = 'width: calc(66.66% - 40px); margin:0 20px ;'
          } else {
            value = 'width: calc(33.33% - 40px); margin:0 20px ;'
          }
        } else {
          console.log('ocultar');
          if (sheet == 'one') {
            value = 'width: calc(100% - 40px); margin:0 20px ;'
          } else {
            value = 'display: none; !important'
          }
        }
        if (screen.width < 767) {
          value = 'width: 100%!important; margin:0 0 20px 0; '
        }
        return value
      },
      formatPhone(data){
        console.clear()
        let value = data
        try {
          console.log(value);
          console.log(typeof value);
          value = value.slice(0,2)+ '('+value.slice(2,5)+')' + value.slice(5,8)+'-'+value.slice(8)
        } catch (error) {
          console.log(error);
        }
        return value
      },
      //View
      async setUserWeb(){
        this.loading('show')
        console.log('>>> USER: '+this.user);
        if (this.user == 'Not authorized') {
          Auth.signIn('smd_webuser', '383@JDU45ss#')
          .then((user) => { 
            this.getPublicOrganization()//Alias
            setTimeout(() => {
              //this.getPublicOrganizationData(this.publicOrganization);  
              this.loading('hidde')
            }, 1500);
          })
          .catch((err) => console.log(err));
        }else{
          this.getPublicOrganization()//Alias
          setTimeout(() => {
            //this.getPublicOrganizationData(this.publicOrganization);  
            this.loading('hidde')
          }, 500);
        }
      }, 
    },
    computed:{
      ...Vuex.mapState(['publicOrganization', 'publicOrganizationData', 'reload', 'user','publicOrgPay','dataUser'])
    }
  }
</script>